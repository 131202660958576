<template>
  <a-modal v-if="modalVisible" v-model="modalVisible" title="通话记录" :width="1250" :footer="null" :centered="true" @cancel="cancelModal" :bodyStyle="bodyStyle">
    <a-table :columns="tableColumns" :data-source="tableData" row-key="calllogid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
      <span slot="begin_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
      <span slot="end_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
<!--      <span slot="duration" slot-scope="value">-->
<!--            <a-tooltip :title="value ? moment(value).format('秒') : ''">-->
<!--              <span>{{value ? moment(value).format('秒') : ''}}</span>-->
<!--            </a-tooltip>-->
<!--          </span>-->
      <span slot="duration" slot-scope="value">
        <a-tooltip :title="value ? moment(value).format('秒') : ''">
          <span>{{value=value+"秒"}}</span>
        </a-tooltip>
      </span>
      <span slot="call_type" slot-scope="value">
        <a-tag>{{value=='IN'?'呼入':'呼出'}}</a-tag>
      </span>
      <span slot="play" slot-scope="value, record">
            <a-button type="link" size="small" @click="play(value, record)">播放录音</a-button>
          </span>
      <span slot="download" slot-scope="value, record">
            <a-button type="link" size="small" @click="download(value, record)">下载</a-button>
          </span>
    </a-table>
    <div id="myframe" class="page-wrapper" style="text-align: center;margin-top: 10px;margin-bottom: -10px" v-show="show">
<!--      <iframe id="content"-->
<!--              :src="tapeUrl" ref="iframe_a"-->
<!--              width="1205"-->
<!--              height="185"-->
<!--              frameborder="no"-->
<!--              border="0"-->
<!--              marginwidth="0"-->
<!--              marginheight="0"-->
<!--              scrolling="no"-->
<!--              allowtransparency="yes">-->
<!--      </iframe>-->
      <audio
          :src="tapeUrl"
          autoplay="autoplay"
          controls="controls"
          ref="audio"
      >Your browser does not support the audio element.</audio>
    </div>
    <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" style="margin-top: 20px; text-align: right;">
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
  </a-modal>
</template>
<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {getCalllog} from "A/thjlcx";
export default{
  name: 'wlwCheckTraceBack',
  mixins: [pagination],
  props: {
    Datadetail:{
      default: null
    },
    visible: {
      default: false
    },
  },
  data(){
    return {
      show:false,
      tapeUrl:'',
      ipnetnum:'',
      begintime:'',
      endtime:'',
      moment,
      callers:'',
      modalVisible:false,
      bodyStyle:{
      },
      tableColumns:[
        {
          title: '主叫号码',
          dataIndex: 'caller',
          key: 'caller',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '被叫号码',
          dataIndex: 'called',
          key: 'called',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '通话开始时间',
          dataIndex: 'begin_time',
          key: 'begin_time',
          ellipsis: true,
          sorter:true,
          sortOrder:'ascend',
          scopedSlots: { customRender: 'begin_time' }
        },
        {
          title: '通话结束时间',
          dataIndex: 'end_time',
          key: 'end_time',
          ellipsis: true,
          scopedSlots: { customRender: 'end_time' }
        },
        {
          title: '通话时间',
          dataIndex: 'duration',
          key: 'duration',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'duration' }
        },
        {
          title: '通话类型',
          dataIndex: 'call_type',
          key: 'call_type',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'call_type' }
        },{
          title:'录音播放',
          align: 'center',
          width: 150,
          scopedSlots: { customRender: 'play' }
        }, {
          title:'录音下载',
          align: 'center',
          width: 150,
          scopedSlots: { customRender: 'download' }
        }
      ],
      tableLoading: false,
      tableData:[]
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
  },
  methods: {
    cancelModal(e) {
      this.modalVisible = false
    },
    init() {
      this.getTableData();
    },
    getTableData(firstPage) {
      this.tableData=[];
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if(this.Datadetail.caller.length>0) {
        for (var i = 0; i < this.Datadetail.caller.length; i++) {
          let params = {
            ...this.queryParams,
            caller: this.Datadetail.caller[i].length>11?this.Datadetail.caller[i].split('-')[1]:this.Datadetail.caller[i],
            begintime: this.Datadetail.begintime,
            endtime: this.Datadetail.endtime,
            pageno: this.currentPage,
            pagesize: this.pagination.pageSize
          };
          getCalllog(params).then(res => {
            this.tableLoading = false;
            if (res && res.returncode == '0') {
              for(var j=0;j<res.item.length;j++){
                this.tableData.push(res.item[j]);
              }
            }
          }).catch(err => {
            this.tableLoading = false;
          })
        }
        this.Datadetail.caller=[]
      }else{
        this.tableLoading = false;
      }
    },
    play(value, record) {
       this.playRecords(record);
       this.show=true;
    },
    playRecords(record) {
      // this.showLoading();
      // if (calllogid) {
      //   let params = {
      //     calllogid
      //   };
      //   getCalllog(params).then(res => {
      //     this.hideLoading();
      //     if (res && res.returncode == '0') {
      //       this.$message.success('操作成功');
      //       var playtape = res.item[0].playtape
      //       console.log(playtape)
      //       //window.open(playtape)
      //       this.tapeUrl=playtape
      //     } else {
      //       this.$message.error(res.errormsg || '操作失败');
      //     }
      //   })
      // }
      let saveUrl=record.saveurl
      let caller = record.caller
      let time = moment(record.begin_time,'YYYYMMDDHHmmss').format("YYYY-MM-DD-HH-mm-ss")
      this.tapeUrl=saveUrl+"/"+caller+"-"+time+".mp3"
    },
    download(value,record){
      // this.$confirm({
        // title: '',
        // content: '确定要下载该录音文件吗?',
        // okText: '确定',
        // cancelText: '取消',
        // onOk:() => {
        //   this.getRecords(record.calllogid);
        // },
        // onCancel() {},
      // });
      let saveUrl=record.saveurl
      let caller = record.caller
      let time = moment(record.begin_time,'YYYYMMDDHHmmss').format("YYYY-MM-DD-HH-mm-ss")
      this.tapeUrl=saveUrl+"/"+caller+"-"+time+".mp3"
      window.open(this.tapeUrl)
    },
    getRecords(calllogid){
      this.showLoading();
      if(calllogid) {
        let params = {
          calllogid
        };
        getCalllog(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            var gettape=res.item[0].gettape
            console.log(gettape)
            window.open(gettape)
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  }
}
</script>

