export const titleLists = {
  "main": "AI+物联网故障历史",
  "malfunction": "故障历史",
  "alarm": "报警历史",
  "event": "AI事件历史",
  "elevator": "电梯事件历史",
  "terminal": "物联网终端事件历史",
}

export const eventTypes = {
  "malfunction": "1",
  "alarm": "2",
  "event": "3",
  "elevator": "4",
  "terminal": "5",
}

export const eventstatus = {
  "1": "未处理",
  "2": "已处理",
}
export const eventstatusColors = {
  "1": "red",
  "2": "green",
}

export const eventOptions = [
  { key: 1, value: "故障" },
  { key: 2, value: "报警" },
  { key: 3, value: "AI" },
  { key: 4, value: "电梯事件" },
  { key: 5, value: "物联网终端事件" },
]

export const eventTips = {
  1: '故障',
  2: '报警',
  3: 'AI',
  4: '电梯事件',
  5: '物联网终端事件',
}

export const serviceMode = { 0: "停止服务", 1: "正常运行", 2: "检修", 3: "消防返回", 4: "消防员运行", 5: "应急电源运行", 6: "地震模式", 7: "未知" }
export const carStatus = { "00": "停止", "01": "运行" }
export const carDirection = { "00": "无方向", "01": "上行", "02": "下行" }
// export const doorStatus = { "00": "未关门到位", "01": "关门到位" }
export const doorStatus = { "00": "开门", "01": "关门到位" }
export const hasPassenger = { "00": "无人", "01": "有人" }