<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安装地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
<!--            <a-form-model-item label="查询时段" prop="queryInstallDate">-->
<!--              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>-->
<!--            </a-form-model-item>-->
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
<!--            <a-form-model-item label="安装地址" prop="adress">-->
<!--              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>-->
<!--            </a-form-model-item>-->
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname">
              <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维护单位" prop="maintenancedepname">
              <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button type="primary" @click="deleteBatch()">删除</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
          <div>
            <a-button type="primary" @click="getTableData()">全部历史</a-button>
            <a-button type="primary" @click="cancelHistory()">撤销历史</a-button>
          </div>
        </div>
        <a-table :row-selection="{onChange: rowSelectChange}" :columns="tableColumns" :data-source="tableData" row-key="alarmid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="calledtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="alarmtype" slot-scope="value">
            <span>{{value==0 ? "困人" : ''}}</span>
          </span>
          <span slot="detail" slot-scope="value, record">
            <a-button type="link" size="small" @click="detail(value, record)">详情</a-button>
          </span>
          <span slot="recover" slot-scope="value, record" v-if="recoverflag">
            <a-button type="link" size="small" @click="recover(value, record)">恢复</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="deleteConfirm(value, record)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <traceBack :visible.sync="detailVisible" v-if="" ref="trace" :DetailData="detailData"></traceBack>
  </page-container>
</template>
<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import store from '@/store'
import { mapState } from 'vuex'
import moment from 'moment'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import approvestatusMap from '@/json/approvestatus'
import approvestatuscolors from '@/json/approvestatuscolors'
import {titleLists,eventTypes,eventstatus,eventstatusColors} from '@/json/wlwhistory'
import { getEventrecordListByCondition,deleteEventrecord } from 'A/wlw.js'
import {deleteAlarmFiles, getAlarmFilesListByCondition, modifyAlarmFiles} from "A/thjlcx";
import traceBack from "V/yjcz/emhistory/traceBack";
import callCenter from "C/modals/callCenter";
export default {
  name: 'tiringhistory',
  mixins: [areaselect, deptselect, pagination],
  props: ['pageType'],
  components: {
    traceBack
  },
  data() {
    return {
      moment,
      recoverflag:false,
      detailData:'',
      detailVisible:false,
      eventstatus,
      eventstatusColors,
      approvestatusMap,
      approvestatuscolors,
      showAdvanced: false,
      queryParams: {
        liftnum: '',
        liftrescue: '',
        userdepid: '',
        maintenancedepid: '',
        maintenancegroupid: '',
        userdepname:'',
        maintenancedepname:'',
        adress: '',
        areacode: '',
        starttime:'',
        endtime:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      tableData: [],
      tableLoading: false,
      batchrowids: '',
      counts:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    eventtype(){
      return eventTypes[this.pageType]
    },
    tableColumns(){
      return [
        {
          title: '救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '电梯注册代码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
        {
          title: '报警人',
          dataIndex: 'alarmcaller',
          key: 'alarmcaller',
          ellipsis: true,
        },
        {
          title: '报警电话',
          dataIndex: 'callertel',
          key: 'callertel',
          ellipsis: true,
        },
        {
          title: '报警原因',
          dataIndex: 'alarmtype',
          key: 'alarmtype',
          ellipsis: true,
          scopedSlots: { customRender: 'alarmtype' }
        },
        {
          title: '报警时间',
          dataIndex: 'calledtime',
          key: 'calledtime',
          ellipsis: true,
          width:150,
          scopedSlots: { customRender: 'calledtime' }
        },
        {
          title: '详情',
          key: 'detail',
          align: 'center',
          width: 50,
          scopedSlots: { customRender: 'detail' }
        },
        // {
        //   title: '恢复',
        //   key: 'recover',
        //   align: 'center',
        //   width: 50,
        //   scopedSlots: { customRender: 'recover' }
        // },
        {
          title: '删除',
          key: 'operation',
          align: 'center',
          width: 50,
          scopedSlots: { customRender: 'operation' }
        },
      ]
    },
    pageTitle() {
      return '电梯困人历史';
    },
    breadcrumb() {
      return [
        {
          name: '应急处置',
          path: ''
        },
        {
          name: '应急救援历史',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
        this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
  },
  created() {
    // let time = new Date();
    // let monthNum = moment(time).format("YYYY-MM").slice(5);
    // this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    // this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getTableData();
  },
  methods: {
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = null;
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      this.recoverflag=false
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        alarmtype:'0',
        endding:'1',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      }
      getAlarmFilesListByCondition(params).then(res=>{
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    cancelHistory(){
      this.recoverflag=true,
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        alarmtype:'0',
        cancel:1,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      }
      getAlarmFilesListByCondition(params).then(res=>{
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    recover(value,record){
      let params = {
        alarmid:record.alarmid,
        eventrecordid:'',
        trappednum:'',
        alarmtype:'',
        liftrescue:'',
        adress:'',
        alarmcaller:'',
        callertel:'',
        liftfault:'',
        calledtime:'',
        maintenancedeptime:'',
        maintenancedepcontime:'',
        pristationtime:'',
        pristationcontime:'',
        rescuespecialisttime:'',
        rescuespecialistscontime:'',
        rescueprog:'',
        constime:'',
        prearrtime:'',
        message:'',
        disptime:'',
        dispconstime:'',
        resperarrtime:'',
        resperconstime:'',
        savetime:'',
        rescueconstime:'',
        lifteventype:'',
        faultype:'',
        secondfaultype:'',
        faultdesc:'',
        rescuedby:'',
        rescueres:'',
        rescuenum:'',
        backtype:'',
        arrtime:'',
        mainteva:'',
        miantcomeva:'',
        safeva:'',
        rescuexplain:'',
        safeconn:'',
        userdepconn:'',
        maintenanceper1conn:'',
        maintenanceper2conn:'',
        areamanagerconn:'',
        depmanagerconn:'',
        maintenancedepconn:'',
        stationperson1conn:'',
        stationperson2conn:'',
        pristationpersonconn:'',
        pristationconn:'',
        conn_119:'',
        conn_120:'',
        conn_110:'',
        rescuespecialistsconn:'',
        cancel:'0',
        signforflag:'',
        arriveflag:'',
        rescuendflag:'',
        stopflag:'',
        orderendflag:'',
        endflag:'0',
      }
      console.log(params)
      modifyAlarmFiles(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("恢复成功")
        }else{
          this.$message.error("恢复失败")
        }
      })
    },
    detail(value,record){
      this.detailData=record;
      this.detailVisible=true;
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.alarmid);
        },
        onCancel() {},
      });
    },
    delete(alarmid) {
      // this.showLoading();
      // if(eventrecordid) {
      //   let params = {
      //     eventrecordid
      //   };
      //   deleteEventrecord(params).then(res => {
      //     this.hideLoading();
      //     if(res && res.returncode == '0') {
            let params ={
              alarmid:alarmid
            }
            deleteAlarmFiles(params).then(res => {
              if(res && res.returncode == '0') {
                this.$message.success('操作成功');
                this.getTableData();
              }else {
                this.$message.error(res.errormsg||'操作失败');
              }
            })
          // }else {
          //   this.$message.error(res.errormsg||'操作失败');
          // }
        // })
      // }
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      this.batchrowids = selectedRowKeys.join('|');
    },
    deleteBatch() {
      if(this.batchrowids) {
        console.log(this.batchrowids)
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm({
        // title: '',
        content: '确定要删除选中数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(this.batchrowids);
        },
        onCancel() {},
      });
    },
  }
}
</script>
<style lang="scss" scoped>
</style>