/*
 * @Author: zhaoxin
 * @Date: 2020-11-03 21:54:41
 * @Description: 引用该mixin还需要做以下操作
 * @Description: 1. watch监听 areaCascaderSelected ,处理form表单的areacode
 * @Description: 2. 数据反显调用this.initAreaOptionsByAreacode(areacode)方法
 * @Description: 3. 清空表单时重置this.areaCascaderSelected = []
 * @Description: 4. 模板使用下面代码
 * <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="areaCascaderSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :allow-clear="false"></a-cascader>
 */
import { getItemFromArrayByKey, getTreeDeepString } from 'U'
import { geteChildListByParentcode, getParentTreeBycode } from 'A/xtpz.js'

export default {
  data() {
    return {
      areaOptions: [],
      areaCascaderSelected: [],
    }
  },
  created() {
    this.initAreaOptions();
  },
  methods: {
    initAreaOptions(parentcode='') {
      let params = {
        parentcode
      };
      geteChildListByParentcode(params).then(res => {
        if(res && res.returncode == '0') {
          this.areaOptions = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
        }
      })
    },
    areaOnLoadChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getChildArea(targetOption.areacode);
    },
    getChildArea(parentcode) {
      let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
      let params = {
        parentcode
      }
      geteChildListByParentcode(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
          this.areaOptions = [...this.areaOptions]
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
    initAreaOptionsByAreacode(areacode) {
      let params = {
        areacode
      }
      getParentTreeBycode(params).then(res => {
        if(res && res.returncode == '0') {
          let areacodeTreeStr = getTreeDeepString(res.item, '', 'areacode', 'childArea');
          let deepAreaList = areacodeTreeStr.split('|');
          if(deepAreaList.length > 1) {
            this.initAreaListTree(deepAreaList, 0);
          }else {
            this.areaCascaderSelected = deepAreaList;
          }
        }
      })
    },
    initAreaListTree(deepAreaList, index) {
      if(index < deepAreaList.length-1) {
        let parentcode = deepAreaList[index];
        let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
        let params = {
          parentcode
        }
        geteChildListByParentcode(params).then(res => {
          if(res && res.returncode == '0') {
            targetOption.children = res.item.map(item => {
              item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
              return item;
            });
            // this.areaOptions = [...this.areaOptions]
            this.initAreaListTree(deepAreaList, index+1)
          }
        })
      }else {
        this.areaCascaderSelected = deepAreaList;
      }
    },
  }
}