<template>
  <a-modal v-model="modalVisible" title="报警记录" :width="1250" :footer="null" :centered="true" @cancel="cancelModal">
    <div class="main-container" style="width: 100%">
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :label-col="{span:9}" :wrapper-col="{span:14}">
        <div class="card-content" style="width: 93%">
          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333;">
            <a-button  @click="show1=!show1" style="font-weight: bold; font-size: 19px; background-color: unset">求救呼救信息</a-button>
          </h3>
          <div v-show="show1">
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="alarmfiles.liftrescue" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯位置" prop="adress">
              <a-input v-model.trim="alarmfiles.adress" placeholder="" read-only="read-only"></a-input>
              <a-icon type="orientation" class="after-btn table-refresh" @click=""></a-icon>
            </a-form-model-item>
            <a-form-model-item label="被困人数" prop="trappednum">
              <a-input v-model="alarmfiles.trappednum" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="报警类型" prop="alarmtype">
              <a-select id="alarmtype" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.alarmtype" key="alarmfiles.alarmtype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option value="0">困人</a-select-option>
                <a-select-option value="1">故障</a-select-option>
                <a-select-option value="2">投诉</a-select-option>
                <a-select-option value="3">咨询</a-select-option>
                <a-select-option value="4">打错电话</a-select-option>
                <a-select-option value="5">恶意骚扰</a-select-option>
                <a-select-option value="6">其他</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="呼救人" prop="alarmcaller">
              <a-input v-model="alarmfiles.alarmcaller" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="呼救人电话" prop="callertel" >
              <a-input v-model="alarmfiles.callertel" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯问题" prop="liftfault">
              <a-input v-model="alarmfiles.liftfault" placeholder="" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="接警时间" prop="calledtime">
              <a-date-picker
                  v-model="alarmfiles.calledtime"
                  style="width:100%"
                  :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :disabled="disabled"
                  v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    },
                  ]"
                  :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  placeholder="请选择时间"/>
            </a-form-model-item>
          </div>
          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show2=!show2" style="font-weight: bold; font-size: 19px; background-color: unset">使用单位信息</a-button>
          </h3>
          <div v-show="show2">
            <a-form-model class="form-in-threecolumns" :label-col="{span:9}" :wrapper-col="{span:12}">
              <a-form-model-item label="安全管理员" prop="safeperson">
                <a-input v-model="queryParams.safeperson" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="安全管理员电话" prop="safephone">
                <a-input v-model="queryParams.safephone" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况" prop="safeconn">
                <a-select id="safeconn" placeholder="请选择" v-model="alarmfiles.safeconn" :disabled="disabled" @change="connect(queryParams.safephone,queryParams.safeperson,alarmfiles.safeconn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="使用单位" prop="userdepname">
                <a-input v-model="formDatas.userdepname" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="使用单位电话" prop="telphone">
                  <a-input v-model="queryParams.telphone" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况" prop="userdepconn">
                <a-select id="userdepconn" placeholder="请选择" v-model="alarmfiles.userdepconn" :disabled="disabled" @change="connect(queryParams.telphone,formDatas.userdepname,alarmfiles.userdepconn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show3=!show3" style="font-weight: bold; font-size: 19px; background-color: unset">一级响应信息</a-button>
          </h3>
          <div v-show="show3">
            <a-form-model-item label="维保单位响应时间" prop="maintenancedeptime">
              <a-date-picker
                  v-model="alarmfiles.maintenancedeptime"
                  style="width:100%"
                  :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :disabled="disabled"
                  v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                  :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  placeholder="请选择时间"/>
            </a-form-model-item>
            <a-form-model-item label="维保单位响应耗时" prop="maintenancedepcontime">
              <a-input v-model="alarmfiles.maintenancedepcontime" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model class="form-in-threecolumns" :label-col="{span:9}" :wrapper-col="{span:12}">
              <a-form-model-item label="维保负责人一" prop="maintenanceperson1" >
                <a-input v-model="queryParams.maintenanceperson1" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="维保负责人一电话" prop="maintenancephone1">
                <a-input v-model="queryParams.maintenancephone1" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况" prop="maintenanceper1conn">
                <a-select id="maintenanceper1conn" placeholder="请选择" v-model="alarmfiles.maintenanceper1conn" :disabled="disabled" @change="connect(queryParams.maintenancephone1,queryParams.maintenanceperson1,alarmfiles.maintenanceper1conn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保负责人二" prop="maintenanceperson2">
                <a-input v-model="queryParams.maintenanceperson2" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="维保负责人二电话" prop="maintenancephone2">
                <a-input v-model="queryParams.maintenancephone2" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况" prop="telconn">
                <a-select id="maintenanceper2conn" placeholder="请选择" v-model="alarmfiles.maintenanceper2conn" :disabled="disabled" @change="connect(queryParams.maintenancephone2,queryParams.maintenanceperson2,alarmfiles.maintenanceper2conn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保区域经理" prop="areamanager">
                <a-input v-model="queryParams.areamanager" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="维保区域经理电话" prop="areamanagerphone">
                <a-input v-model="queryParams.areamanagerphone" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况" prop="areamanagerconn">
                <a-select id="areamanagerconn" placeholder="请选择" v-model="alarmfiles.areamanagerconn" :disabled="disabled" @change="connect(queryParams.areamanagerphone,queryParams.areamanager,alarmfiles.areamanagerconn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保单位经理" prop="depmanager">
                <a-input v-model="queryParams.depmanager" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="维保单位经理电话" prop="depmanagerphone">
                <a-input v-model="queryParams.depmanagerphone" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况" prop="depmanagerconn">
                <a-select id="depmanagerconn" placeholder="请选择" v-model="alarmfiles.depmanagerconn" :disabled="disabled" @change="connect(queryParams.depmanagerphone,queryParams.depmanager,alarmfiles.depmanagerconn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保单位" prop="maintenancedepname">
                <a-input v-model="formDatas.maintenancedepname" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="维保单位电话" prop="maintenancedephone">
                <a-input v-model="queryParams.maintenancedephone" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况" prop="maintenancedepconn">
                <a-select id="maintenancedepconn" placeholder="请选择" v-model="alarmfiles.maintenancedepconn" :disabled="disabled" @change="connect(queryParams.maintenancedephone,formDatas.maintenancedepname,alarmfiles.maintenancedepconn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show4=!show4" style="font-weight: bold; font-size: 19px; background-color: unset">二级响应信息</a-button>
          </h3>
          <div v-show="show4">
            <a-form-model-item label="救援机构响应时间" prop="pristationtime">
              <a-date-picker
                  v-model="alarmfiles.pristationtime"
                  style="width:100%"
                  :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :disabled="disabled"
                  v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                  :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  placeholder="请选择时间"/>
            </a-form-model-item>
            <a-form-model-item label="救援机构响应耗时" prop="pristationcontime" style="">
              <a-input v-model="alarmfiles.pristationcontime" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model class="form-in-threecolumns" :label-col="{span:9}" :wrapper-col="{span:12}">
              <a-form-model-item label="驻站人一">
                <a-input v-model="queryParams.stationperson1" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="驻站人一电话">
                <a-input v-model="queryParams.stationphone1" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况">
                <a-select id="stationperson1conn" placeholder="请选择" v-model="alarmfiles.stationperson1conn" :disabled="disabled" @change="connect(queryParams.stationphone1,queryParams.stationperson1,alarmfiles.stationperson1conn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="驻站人二">
                <a-input v-model="queryParams.stationperson2" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="驻站人二电话">
                <a-input v-model="queryParams.stationphone2" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况">
                <a-select id="stationperson2conn" placeholder="请选择" v-model="alarmfiles.stationperson2conn" :disabled="disabled" @change="connect(queryParams.stationphone2,queryParams.stationperson2,alarmfiles.stationperson2conn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="二级救援负责人" prop="pristationperson">
                <a-input v-model="queryParams.pristationperson" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="二级救援负责人电话" prop="pristationperphone">
                <a-input v-model="queryParams.pristationperphone" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况">
                <a-select id="pristationpersonconn" placeholder="请选择" v-model="alarmfiles.pristationpersonconn" :disabled="disabled" @change="connect(queryParams.pristationperphone,queryParams.pristationperson,alarmfiles.pristationpersonconn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="二级救援站" prop="pristation">
                <a-input v-model="queryParams.pristation" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="二级救援站电话" prop="pristationphone">
                <a-input v-model="queryParams.pristationphone" placeholder="" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况">
                <a-select id="pristationconn" placeholder="请选择" v-model="alarmfiles.pristationconn" :disabled="disabled" @change="connect(queryParams.pristationphone,queryParams.pristation,alarmfiles.pristationconn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show5=!show5" style="font-weight: bold; font-size: 19px; background-color: unset">三级响应信息</a-button>
          </h3>
          <div v-show="show5">
            <a-form-model class="form-in-threecolumns" :label-col="{span:9}" :wrapper-col="{span:12}">
              <a-form-model-item>
                <span style="font-size:30px;margin-left:72%;">119</span>
              </a-form-model-item>
              <a-form-model-item>
                  <span style="font-size:30px;margin-left:72%;">120</span>
              </a-form-model-item>
              <a-form-model-item>
                  <span style="font-size:30px;margin-left:72%;">110</span>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况">
                <a-select id="conn_119" placeholder="请选择" v-model="alarmfiles.conn_119" :disabled="disabled" @change="connect(119,'火警电话',alarmfiles.conn_119)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况">
                <a-select id="conn_120" placeholder="请选择" v-model="alarmfiles.conn_120" :disabled="disabled" @change="connect(120,'急救电话',alarmfiles.conn_120)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况">
                <a-select id="conn_110" placeholder="请选择" v-model="alarmfiles.conn_110" :disabled="disabled" @change="connect(110,'报警电话',alarmfiles.conn_110)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未派人</a-select-option>
                  <a-select-option :value="2">接通已派人</a-select-option>
                  <a-select-option :value="3">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show6=!show6" style="font-weight: bold; font-size: 19px; background-color: unset">救援专家</a-button>
          </h3>
          <div v-show="show6">
            <a-form-model-item label="救援专家响应时间" prop="rescuespecialisttime">
              <a-date-picker
                  v-model="alarmfiles.rescuespecialisttime"
                  style="width:100%"
                  :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :disabled="disabled"
                  v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                  :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  placeholder="请选择时间"/>
            </a-form-model-item>
            <a-form-model-item label="救援专家响应耗时" prop="rescuespecialistscontime" style="">
              <a-input v-model="alarmfiles.rescuespecialistscontime" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model class="form-in-threecolumns" :label-col="{span:9}" :wrapper-col="{span:12}">
              <a-form-model-item label="专家组" prop="panel">
                <a-select placeholder="请选择" v-model="alarmfiles.panel" :disabled="disabled">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">专家组1</a-select-option>
                  <a-select-option :value="1">曳引系统专家</a-select-option>
                  <a-select-option :value="2">维保单位</a-select-option>
                  <a-select-option :value="3">检验机构</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="专家" prop="specialist" v-model="alarmfiles.specialist" :disabled=true>
                <a-select placeholder="请选择">
                  <a-select-option value="">请选择</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="专家电话" prop="specialistphone">
                <a-input  placeholder="" style="width:135px;margin-top: -100px" v-model="alarmfiles.specialistphone" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电话接通情况">
                <a-select id="rescuespecialistsconn" placeholder="请选择" :disabled="disabled" v-model="alarmfiles.rescuespecialistsconn" @change="connect(alarmfiles.specialistphone,alarmfiles.specialist,alarmfiles.rescuespecialistsconn)">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option :value="0">未接通</a-select-option>
                  <a-select-option :value="1">接通未排人</a-select-option>
                  <a-select-option :value="1">接通已排人</a-select-option>
                  <a-select-option :value="2">信息不正确</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show7=!show7" style="font-weight: bold; font-size: 19px; background-color: unset">当前状态</a-button>
          </h3>
          <div v-show="show7">
            <a-form-model class="form-in-fourcolumns" :label-col="{span:12}" :wrapper-col="{span:12}">
              <a-form-model-item label="救援进展情况" prop="rescueprog">
                <a-icon type="redo" class="after-btn table-refresh" @click="getAlarmFiles" disabled="disabled"></a-icon>
              </a-form-model-item>
              <a-form-model-item label="救援总时间" prop="constime" :label-col="{span:12}" :wrapper-col="{span:12}">
                <a-input  type="text" v-model="constimes" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="预计到达现场时间" prop="prearrtime" :wrapper-col="{span:10}">
                <a-input  type="text" v-model="alarmfiles.prearrtime" placeholder="单位为分钟" read-only="read-only"></a-input>
              </a-form-model-item>
              <a-form-model-item label="短信历史" prop="message">
                <a-icon type="redo" class="after-btn table-refresh" @click="getAlarmFiles" disabled="true"></a-icon>
              </a-form-model-item>
            </a-form-model>
            <a-form-model style="width: 100%">
              <a-form-model-item prop="rescueprog" style="width: 48%;">
                <textarea v-model="alarmfiles.rescueprog" style="width:300%;height:150px;margin-left: 35%" :disabled="disabled" placeholder="救援进展情况"></textarea>
              </a-form-model-item>
              <a-form-model-item prop="message" style="width: 48%;">
                <textarea v-model="alarmfiles.message" style="width:300%;height:150px;margin-left: 36%" :disabled="disabled" placeholder="短信历史"></textarea>
              </a-form-model-item>
            </a-form-model>
            <a-form-model-item label="派遣时间" prop="disptime">
              <a-date-picker
                  v-model="alarmfiles.disptime"
                  style="width:100%"
                  :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :disabled="true"
                  v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                  :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  placeholder="请选择时间"/>
            </a-form-model-item>
            <a-form-model-item label="派遣耗时" prop="dispconstime">
              <a-input type="text" v-model="alarmfiles.dispconstime" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="响应人员到达时间" prop="resperarrtime">
              <a-date-picker
                  v-model="alarmfiles.resperarrtime"
                  style="width:100%"
                  :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :disabled="true"
                  v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                  :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  placeholder="请选择时间"/>
            </a-form-model-item>
            <a-form-model-item label="响应人员到达耗时" prop="resperconstime">
              <a-input v-model="alarmfiles.resperconstime" read-only="read-only"></a-input>
            </a-form-model-item>
            <a-form-model-item label="解救成功时间" prop="savetime">
              <a-date-picker
                  v-model="alarmfiles.savetime"
                  style="width:100%"
                  :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :disabled="disabled"
                  v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                  :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  placeholder="请选择时间"/>
            </a-form-model-item>
            <a-form-model-item label="救援耗时" prop="rescueconstime">
              <a-input v-model="alarmfiles.rescueconstime" read-only="read-only"></a-input>
            </a-form-model-item>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show8=!show8" style="font-weight: bold; font-size: 19px; background-color: unset">救援反馈</a-button>
          </h3>
          <div v-show="show8">
            <a-form-model-item label="电梯事件类型">
              <a-select id="lifteventype" placeholder="请选择" v-model="alarmfiles.lifteventype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option value="0">人员伤亡</a-select-option>
                <a-select-option value="1">困人</a-select-option>
                <a-select-option value="2">非困人故障</a-select-option>
                <a-select-option value="3">其他</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="被谁救出" prop="rescuedby">
              <a-input v-model="alarmfiles.rescuedby" placeholder=""></a-input>
            </a-form-model-item>
            <a-form-model-item label="故障类型" prop="faultype">
              <a-select style="width: 100%" placeholder="请选择" @change="onReasonChange" v-model="alarmfiles.faultype">
                <a-select-option v-for="firstreson in firstreson" :key="firstreson.reson">
                  {{ firstreson.reson }}
                </a-select-option>
              </a-select>
              <a-select style="width: 100%" placeholder="请选择" v-model="alarmfiles.secondfaultype">
                <a-select-option v-for="secondreson in selcity" :key="secondreson">
                  {{ secondreson }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="救援结果" prop="rescueres">
              <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.rescueres" required>
                <a-radio :value="0">
                  人已救出
                </a-radio>
                <a-radio :value="1">
                  其他
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="故障描述" prop="faultdesc">
              <textarea v-model="alarmfiles.faultdesc" style="width:336px;height:100px" placeholder="请输入"></textarea>
            </a-form-model-item>
            <a-form-model-item label="解救人数" prop="rescuenum">
              <a-input v-model="alarmfiles.rescuenum" placeholder=""></a-input>
            </a-form-model-item>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show9=!show9" style="font-weight: bold; font-size: 19px; background-color: unset">救援评价</a-button>
          </h3>
          <div v-show="show9">
            <a-form-model-item label="回访方式" prop="backtype">
              <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.backtype" required>
                <a-radio :value="0">
                  首次电话回访
                </a-radio>
                <a-radio :value="1">
                  现场回访
                </a-radio>
                <a-radio :value="2">
                  常规电话回访
                </a-radio>
                <a-radio :value="3">
                  其他
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="维保人员到达时间" prop="arrtime">
              <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.arrtime" required>
                <a-radio :value="0">
                  30分钟以内
                </a-radio>
                <a-radio :value="1">
                  超过30分钟
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="维保人员评价" prop="mainteva">
              <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.mainteva" required>
                <a-radio :value="0">
                  良好
                </a-radio>
                <a-radio :value="1">
                  好
                </a-radio>
                <a-radio :value="2">
                  一般
                </a-radio>
                <a-radio :value="3">
                  差
                </a-radio>
                <a-radio :value="4">
                  恶劣
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="维保公司响应评价" prop="miantcomeva">
              <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.miantcomeva" required>
                <a-radio :value="0">
                  良好
                </a-radio>
                <a-radio :value="1">
                  好
                </a-radio>
                <a-radio :value="2">
                  一般
                </a-radio>
                <a-radio :value="3">
                  差
                </a-radio>
                <a-radio :value="4">
                  恶劣
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="安全管理人员评价" prop="safeva">
              <a-radio-group name="radioGroup" :default-value="0" v-model="alarmfiles.safeva" required>
                <a-radio :value="0">
                  良好
                </a-radio>
                <a-radio :value="1">
                  好
                </a-radio>
                <a-radio :value="2">
                  一般
                </a-radio>
                <a-radio :value="3">
                  差
                </a-radio>
                <a-radio :value="4">
                  恶劣
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="救援情况说明" prop="rescuexplain">
              <a-textarea v-model="alarmfiles.rescuexplain" placeholder="请输入" required></a-textarea>
            </a-form-model-item>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show10=!show10" style="font-weight: bold; font-size: 19px; background-color: unset">录音历史</a-button>
          </h3>
          <div v-show="show10">
            <a-form-model style="text-align: center">
              <a-form-model style="text-align: center">
                <a-button type="primary" @click="getRecords">查看录音</a-button>
              </a-form-model>
            </a-form-model>
          </div>

          <h3 style="font-weight: bold; background:#DCDBDC; width:107.5%; color: #333; margin-top: 12px">
            <a-button  @click="show11=!show11" style="font-weight: bold; font-size: 19px; background-color: unset">图片和视频</a-button>
          </h3>
          <div v-show="show11" style="margin-left: 3%">
            <a-button @click="appOrPlat(true)" :class="buttonIndex == 1?'bgcolor':''">平台</a-button>
            <a-button @click="appOrPlat(false)" :class="buttonIndex == 2?'bgcolor':''">APP</a-button>
            <div v-if="pc==true">
              <h3>图片:</h3>
              <a-form-model>
                <img :src="picUrl1" v-if="picVisible" style="width: 500px;height: auto">
                <img :src="picUrl2" v-if="picVisible" style="width: 500px;height: auto">
                <img :src="picUrl3" v-if="picVisible" style="width: 500px;height: auto">
              </a-form-model>
              <h3>视频:</h3>
              <a-form-model>
              </a-form-model>
            </div>
            <div v-if="pc==false">
                <a-form-model style="overflow: hidden">
                  <a-form-model-item>
                    <h3>签到图片:</h3>
                    <img :src="img1" v-if="imgVisible" style="width: 500px;height: 400px;margin-left: 10px">
                  </a-form-model-item>
                  <a-form-model-item>
                    <h3>现场图片:</h3>
                    <img :src="img2" v-if="imgVisible" style="width: 500px;height: 400px;margin-left: 10px">
                  </a-form-model-item>
                </a-form-model>
              <a-form-model>
                <a-form-model-item>
                  <h3>现场视频:</h3>
                  <video :src="video" autoplay controls v-if="imgVisible" style="width: 500px;height: 400px;margin-left: 10px;margin-left: 10px"></video>
                </a-form-model-item>
                <a-form-model-item>
                  <h3>签名图片:</h3>
                  <img :src="img3" v-if="imgVisible" style="width: 500px;height: 400px;margin-left: 10px">
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
          <checkTraceBack :visible.sync="checkTraceVisible" ref="checkTrace" :Datadetail="detaildata"></checkTraceBack>
          <div class="save" style="text-align: right">
            <a-button type="primary" @click="save">保存</a-button>
            <a-button type="primary" @click="close">关闭</a-button>
          </div>
        </div>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {addAlarmFiles, findRescuepicByAlarmId, getCalllog, modifyAlarmFiles, viewUserImg} from "A/thjlcx";
import pagination from "@/mixins/pagination";
import moment from "moment";
import {getLiftinfoById, getLiftinfoListByCondition, getUserdepListByCondition} from "A/jcgn";
import {getAlarmFilesListByCondition} from "A/thjlcx";
import {getEventrecordListByCondition} from "A/wlw";
import {getCache} from "U/index";
import "A/jquery-1.7.1.min.js"
import "A/agentuc.js"
import checkTraceBack from "V/yjcz/emergency/checkTraceBack";
import {getMentenacegroupById} from "A/xtpz";
export default{
  name: 'traceBack',
  components:{
    checkTraceBack
  },
  props: {
    DetailData:{
      default:null
    },
    visible: {
      default: false
    },
  },
  data(){
    return {
      moment,
      checkTraceVisible:false,
      detaildata:'',
      modalVisible:false,
      disabled:true,
      show0: true,
      show1: true,
      show2: true,
      show3: true,
      show4: false,
      show5: false,
      show6: false,
      show7: true,
      show8: false,
      show9: false,
      show10: false,
      show11: false,
      pages: '',
      picVisible:false,
      imgVisible:false,
      videoVisible: false,
      username: '',
      data: [],

      //工单状态
      signforflag: true,
      arriveflag: false,
      rescuendflag: false,
      stopflag: true,
      orderendflag: false,

      //图片
      baseUrl:"https://esp.u-lake.com/",
      picUrl1:'',
      picUrl2:'',
      picUrl3:'',
      fileField:'',
      urlList:[],
      pc:true,
      buttonIndex:1,
      imgLists:[],
      locaImgLists:[],
      img1:'',
      img2:'',
      img3:'',
      video:'',

      //录音
      show: '',
      playtape: '',
      gettape: '',
      tapeUrl: '',

      //通话
      opcall: '',
      msg_div: '',
      user: '',
      password: '',
      login_ext_no: '',
      login_queue: '',
      stat_div: '',
      dia_num: '',
      caller:'',
      callers:[],

      constimes: '',

      firstreson: [{value: '', reson: '请选择'}, {value: 0, reson: '外部原因'}, {value: 1, reson: '人为原因'}, {
        value: 2,
        reson: '门系统'
      }, {value: 3, reson: '曳引系统'},
        {value: 4, reson: '导向系统'}, {value: 5, reson: '轿厢'}, {value: 6, reson: '控制系统'}, {
          value: 7,
          reson: '电气系统'
        }, {value: 8, reson: '安全保护装置'}],
      //城市
      secondreson: [
        ['生活垃圾导致开关门受阻电梯停止运行', '野蛮搬运导致门变形电梯无法运行', '装修垃圾导致开关门受阻电梯停止运行', '超载', '阻挡关门时间过长电梯无法运行', '其他人为原因故障'],
        ['电梯在运行过程中出现的停电', '电气部件进水导致的短路故障', '机房温度过高电气控制系统自动保护', '故障后自动恢复运行', '其他外部原因故障'],
        ['轿门锁失效', '厅门锁失效', '门机故障', '门刀与滚轮（球）间距调整不良', '安全触板、光幕等防夹人保护装置无效', '主动门与从动门之间的联动失效', '门触点失效', '门挂轮破损', '门导向系统失效', '其他门系统故障'],
        ['平衡系数不在标准范围内', '曳引轮（轴）磨损', '曳引机缺油或油量过多', '传动皮带过松', '钢丝绳卡阻或跳槽', '钢丝绳磨损', '变速箱故障', '其他曳引系统故障'],
        ['导靴磨损', '导轨润滑欠缺', '其他导向系统故障'],
        ['轿厢壁变形', '照明失效', '紧急报警失效', '其他轿厢故障'],
        ['控制主板失效', '电气元器件（接触器、继电器、驱动器件等）失效 ', '平层感应器失效', '极限开关、急停开关、维修开关等传感器失效', '电气连接不可靠', '编码器失效', '主电源开关失效', '召唤失效', '电梯重启故障', '其他控制系统故障'],
        ['变频器失效', '电动机失效', '制动器故障', '主电源断开', '其他电气系统故障'],
        ['安全回路断开', '消防开关失效', '限速器开关动作', '安全钳开关动作', '缓冲器开关动作', '张紧轮开关动作', '上行超速保护开关动作', '其他安全保护装置故障'],
      ],
      //根据第一个选项 获取第二给下拉框的数据（也可以是后台ajax请求）
      selcity: [],
      //第二个下拉框的默认选中值
      // second:'',

      lifteventypeList: [{id: 0, title: "人员伤亡"}, {id: 1, title: "困人"}, {id: 2, title: "非困人故障"}, {id: 3, title: "其他"}],
      alarmfiles: {
        alarmid: '',
        // eventrecordid: '',
        trappednum: '',
        liftrescue: '',
        adress: '',
        alarmtype: '',
        alarmcaller: '',
        callertel: '',
        telconn: '',
        liftfault: '',
        calledtime: '',
        maintenancedeptime: '',
        maintenancedepcontime: '',
        pristationtime: '',
        pristationcontime: '',
        rescuespecialisttime: '',
        rescuespecialistscontime: '',
        rescueprog: '',
        constime: '',
        prearrtime: '',
        message: '',
        disptime: '',
        dispconstime: '',
        resperarrtime: '',
        resperconstime: '',
        savetime: '',
        rescueconstime: '',
        lifteventype: '',
        faultype: '',
        secondfaultype: '',
        faultdesc: '',
        rescuedby: '',
        rescueres: '',
        rescuenum: '',
        backtype: '',
        arrtime: '',
        mainteva: '',
        miantcomeva: '',
        safeva: '',
        rescuexplain: '',
        safeconn: '',
        userdepconn: '',
        maintenanceper1conn: '',
        maintenanceper2conn: '',
        areamanagerconn: '',
        depmanagerconn: '',
        maintenancedepconn: '',
        stationperson1conn: '',
        stationperson2conn: '',
        pristationpersonconn: '',
        pristationconn: '',
        conn_119: '',
        conn_120: '',
        conn_110: '',
        rescuespecialistsconn: '',
        panel: '',
        specialist: '',
        specialistphone: '',
        cancel: 0,
        eventcode:'',
        conductor:'',
      },
      queryParams: {
        safeperson: '',
        safephone: '',
        userdepname: '',
        telphone: '',
        maintenanceperson1: '',
        maintenancephone1: '',
        maintenanceperson2: '',
        maintenancephone2: '',
        areamanager: '',
        areamanagerphone: '',
        depmanager: '',
        depmanagerphone: '',
        stationperson1: '',
        stationphone1: '',
        stationperson2: '',
        stationphone2: '',
        pristationperson: '',
        pristationperphone: '',
        pristation: '',
        pristationphone: '',
        maintenancedephone: '',
      },
      formDatas: {
        liftid:'',
        liftnum: '',
        liftrescue: '',
        traceabilitynum: '',
        adress: '',
        userdepid: '',
        userdepname: '',
        maintenancedepname: '',
      },
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getAlarmFiles();
        this.getLiftrescue();
        // this.getEventrecord();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.callers=[]
        this.$emit('update:visible', false);
      }
    },
    'show11':function (){
      if(this.show11==true){
        this.getPic()
      }
    },
  },
  methods: {
    cancelModal(e) {
      this.modalVisible = false
    },
    initSpan() {
      this.getAlarmFiles();
      this.getLiftrescue();
      this.getEventrecord();
    },
    initData(){
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
        this.caller=logininfo.phonextnum;
        this.areacode=logininfo.areacode;
        this.user=logininfo.phonename;
        this.password=logininfo.phonepassword;
        this.login_ext_no=logininfo.phonextnum;
      }
    },
    getWeftFiles(){
      let params = {
        liftid:this.formDatas.liftid
      };
      getLiftinfoById(params).then(res => {
        if(res && res.returncode == '0') {
          this.queryParams.safeperson=res.item.userdepLists.safeperson,
          this.queryParams.safephone=res.item.userdepLists.safephone,
          this.queryParams.userdepname=res.item.userdepLists.userdepname
          this.queryParams.telphone=res.item.userdepLists.telphone,
          this.queryParams.maintenanceperson1=res.item.maintenancedepLists.maintenanceperson1,
          this.queryParams.maintenancephone1=res.item.maintenancedepLists.maintenancephone1,
          this.queryParams.maintenanceperson2=res.item.maintenancedepLists.maintenanceperson2,
          this.queryParams.maintenancephone2=res.item.maintenancedepLists.maintenancephone2,
          this.queryParams.areamanager=res.item.maintenancedepLists.areamanager,
          this.queryParams.areamanagerphone=res.item.maintenancedepLists.areamanagerphone,
          this.queryParams.depmanager=res.item.maintenancedepLists.depmanager,
          this.queryParams.depmanagerphone=res.item.maintenancedepLists.depmanagerphone,
          this.queryParams.stationperson1=res.item.rescuedepLists.stationperson1,
          this.queryParams.stationphone1=res.item.rescuedepLists.stationphone1,
          this.queryParams.stationperson2=res.item.rescuedepLists.stationperson2,
          this.queryParams.stationphone2=res.item.rescuedepLists.stationphone2,
          this.queryParams.pristationperson=res.item.rescuedepLists.pristationperson,
          this.queryParams.pristationperphone=res.item.rescuedepLists.pristationperphone,
          this.queryParams.pristation=res.item.rescuedepLists.pristation,
          this.queryParams.pristationphone=res.item.rescuedepLists.pristationphone,
          this.queryParams.maintenancedep=res.item.rescuedepLists.maintenancedep,
          this.queryParams.maintenancedephone=res.item.rescuedepLists.maintenancedephone
          if(this.caller){
            this.callers.push(this.caller)
          }
          //使用单位
          if(res.item.userdeppreson!=''&&res.item.userdeppersonphone!=''){
            if(this.callers.indexOf(res.item.userdeppersonphone) > -1){
            }else{
              this.callers.push(res.item.userdeppersonphone)
            }
          }
          if(res.item.userdepLists.userdepname!=''&&res.item.userdepLists.telphone!=''){
            if(this.callers.indexOf(res.item.userdepLists.telphone) > -1){
            }else{
              this.callers.push(res.item.userdepLists.telphone)
            }
          }
          if(res.item.userdepLists.safeperson!=''&&res.item.userdepLists.safephone!=''){
            if(this.callers.indexOf(res.item.userdepLists.safephone) > -1){
            }else{
              this.callers.push(res.item.userdepLists.safephone)
            }
          }
          //维保单位
          if(res.item.maintenancedepLists.userdepname!=''&&res.item.maintenancedepLists.telphone!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.telphone) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.telphone)
            }
          }
          if(res.item.maintenancedepLists.maintenanceperson1!=''&&res.item.maintenancedepLists.maintenancephone1!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.maintenancephone1) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.maintenancephone1)
            }
          }
          if(res.item.maintenancedepLists.maintenanceperson2!=''&&res.item.maintenancedepLists.maintenancephone2!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.maintenancephone2) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.maintenancephone2)
            }
          }
          if(res.item.maintenancedepLists.areamanager!=''&&res.item.maintenancedepLists.areamanagerphone!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.areamanagerphone) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.areamanagerphone)
            }
          }
          if(res.item.maintenancedepLists.depmanager!=''&&res.item.maintenancedepLists.depmanagerphone!=''){
            if(this.callers.indexOf(res.item.maintenancedepLists.depmanagerphone) > -1){
            }else{
              this.callers.push(res.item.maintenancedepLists.depmanagerphone)
            }
          }
          if(res.item.maintenancedepLists.rescueperson!='' && res.item.maintenancedepLists.rescuephone!='') {
            if(res.item.maintenancedepLists.rescuephone.length<13){
              if(this.callers.indexOf(res.item.maintenancedepLists.rescuephone) > -1){
              }else{
                this.callers.push(res.item.maintenancedepLists.rescuephone)
              }
            }else{
              let strs = res.item.maintenancedepLists.rescuephone.split(",");
              for(var n=0;n<strs.length;n++){
                if(this.callers.indexOf(strs[n]) > -1){
                }else{
                  this.callers.push(strs[n])
                }
              }
            }
          }
          //救援单位
          if(res.item.rescuedepLists.userdepname!=''&&res.item.rescuedepLists.telphone!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.telphone) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.telphone)
            }
          }
          if(res.item.rescuedepLists.stationperson1!=''&&res.item.rescuedepLists.stationphone1!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.stationphone1) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.stationphone1)
            }
          }
          if(res.item.rescuedepLists.stationperson2!=''&&res.item.rescuedepLists.stationphone2!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.stationphone2) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.stationphone2)
            }
          }
          if(res.item.rescuedepLists.pristationperson!=''&&res.item.rescuedepLists.pristationperphone!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.pristationperphone) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.pristationperphone)
            }
          }
          if(res.item.rescuedepLists.pristation!=''&&res.item.rescuedepLists.pristationphone!=''){
            if(this.callers.indexOf(res.item.rescuedepLists.pristationphone) > -1){
            }else{
              this.callers.push(res.item.rescuedepLists.pristationphone)
            }
          }
          let params ={
            maintenancegroupid:res.item.maintenancegroupid
          }
          getMentenacegroupById(params).then(res=>{
            if(res && res.returncode == '0') {
              for(var i=0;i<res.item.memberList.length;i++){
                if(this.callers.indexOf(res.item.memberList[i].phonenum) > -1){
                }else{
                  this.callers.push(res.item.memberList[i].phonenum)
                }
              }
            }
          })
        }
      }).catch(err => {
      })
    },
    getEventrecord(){
      let params = {
        liftrescue: this.DetailData.liftrescue,
        eventcode:"02",
        eventtype:2,
        eventstatus:1
      };
      getEventrecordListByCondition(params).then(res=>{
        if(res && res.returncode == '0'){
          this.alarmfiles.eventrecordid=res.item[0].eventrecordid
        }
      })
    },
    getLiftrescue() {
      let params = {
        liftrescue: this.DetailData.liftrescue,
        // userdepid:this.unitId[0],
      };
      getLiftinfoListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.formDatas.liftid=res.item[0].liftid,
          this.formDatas.liftrescue=res.item[0].liftrescue,
              this.formDatas.adress=res.item[0].adress,
              this.formDatas.userdepid=res.item[0].userdepid,
              this.formDatas.userdepname=res.item[0].userdepname,
              this.formDatas.maintenancedepname=res.item[0].maintenancedepname,
              this.alarmfiles.liftrescue=res.item[0].liftrescue,
              this.alarmfiles.adress=res.item[0].adress,
              this.alarmfiles.devicenum=res.item[0].devicenum,
              this.getWeftFiles();
        }
      }).catch(err => {
      })
      return 0
    },
    getAlarmFiles(){
      let params = {
        // ...this.alarmfiles
        eventrecordid:this.DetailData.eventrecordid,
        alarmid:this.DetailData.alarmid
      };
      getAlarmFilesListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.alarmfiles.alarmid=res.item[0].alarmid
          this.alarmfiles.trappednum=res.item[0].trappednum
          this.alarmfiles.alarmtype=res.item[0].alarmtype
          this.alarmfiles.alarmcaller=res.item[0].alarmcaller
          this.alarmfiles.callertel=res.item[0].callertel
          this.alarmfiles.liftrescue=res.item[0].liftrescue
          this.alarmfiles.adress=res.item[0].adress
          this.alarmfiles.liftfault=res.item[0].liftfault
          this.alarmfiles.calledtime=res.item[0].calledtime
          this.alarmfiles.maintenancedeptime=res.item[0].maintenancedeptime
          this.alarmfiles.maintenancedepcontime=res.item[0].maintenancedepcontime
          this.alarmfiles.pristationtime=res.item[0].pristationtime
          this.alarmfiles.pristationcontime=res.item[0].pristationcontime
          this.alarmfiles.rescuespecialisttime=res.item[0].rescuespecialisttime
          this.alarmfiles.rescuespecialistscontime=res.item[0].rescuespecialistscontime
          this.alarmfiles.rescueprog=res.item[0].rescueprog
          this.constimes=res.item[0].constime
          this.alarmfiles.prearrtime=res.item[0].prearrtime
          this.alarmfiles.message=res.item[0].message
          this.alarmfiles.disptime=res.item[0].disptime
          this.alarmfiles.dispconstime=res.item[0].dispconstime
          this.alarmfiles.resperarrtime=res.item[0].resperarrtime
          this.alarmfiles.resperconstime=res.item[0].resperconstime
          this.alarmfiles.savetime=res.item[0].savetime
          this.alarmfiles.rescueconstime=res.item[0].rescueconstime
          this.alarmfiles.savetime=res.item[0].savetime
          this.alarmfiles.rescueconstime=res.item[0].rescueconstime
          this.alarmfiles.lifteventype=res.item[0].lifteventype
          this.alarmfiles.faultype=res.item[0].faultype
          this.alarmfiles.secondfaultype=res.item[0].secondfaultype
          this.alarmfiles.faultdesc=res.item[0].faultdesc
          this.alarmfiles.rescuedby=res.item[0].rescuedby
          this.alarmfiles.rescueres=res.item[0].rescueres
          this.alarmfiles.rescuenum=res.item[0].rescuenum
          this.alarmfiles.backtype=res.item[0].backtype
          this.alarmfiles.arrtime=res.item[0].arrtime
          this.alarmfiles.mainteva=res.item[0].mainteva
          this.alarmfiles.miantcomeva=res.item[0].miantcomeva
          this.alarmfiles.safeva=res.item[0].safeva
          this.alarmfiles.rescuexplain=res.item[0].rescuexplain
          this.alarmfiles.safeconn=res.item[0].safeconn
          this.alarmfiles.userdepconn=res.item[0].userdepconn
          this.alarmfiles.maintenanceper1conn=res.item[0].maintenanceper1conn
          this.alarmfiles.maintenanceper2conn=res.item[0].maintenanceper2conn
          this.alarmfiles.areamanagerconn=res.item[0].areamanagerconn
          this.alarmfiles.depmanagerconn=res.item[0].depmanagerconn
          this.alarmfiles.maintenancedepconn=res.item[0].maintenancedepconn
          this.alarmfiles.stationperson1conn=res.item[0].stationperson1conn
          this.alarmfiles.stationperson2conn=res.item[0].stationperson2conn
          this.alarmfiles.pristationpersonconn=res.item[0].pristationpersonconn
          this.alarmfiles.pristationconn=res.item[0].pristationconn
          this.alarmfiles.conn_119=res.item[0].conn_119
          this.alarmfiles.conn_120=res.item[0].conn_120
          this.alarmfiles.conn_110=res.item[0].conn_110
          this.alarmfiles.rescuespecialistsconn=res.item[0].rescuespecialistsconn
          this.alarmfiles.panel=res.item[0].panel
          this.alarmfiles.specialist=res.item[0].specialist
          this.alarmfiles.specialistphone=res.item[0].specialistphone
          this.alarmfiles.eventcode=res.item[0].eventcode
          this.alarmfiles.conductor=res.item[0].conductor
          if(this.alarmfiles.conductor){
            this.callers.push(this.alarmfiles.conductor)
          }
          if(this.alarmfiles.callertel){
            this.callers.push(this.alarmfiles.callertel)
          }
          if(res.item[0].signforflag=='false'){
            this.signforflag=false
          }else{
            this.signforflag=true
          }
          if(res.item[0].arriveflag=='false'){
            this.arriveflag=false
          }else{
            this.arriveflag=true
          }
          if(res.item[0].rescuendflag=='false'){
            this.rescuendflag=false
          }else{
            this.rescuendflag=true
          }
          if(res.item[0].stopflag=='false'){
            this.stopflag=false
          }else{
            this.stopflag=true
          }
          if(res.item[0].orderendflag=='false'){
            this.orderendflag=false
          }else{
            this.orderendflag=true
          }
        }
        this.getLiftrescue();
      }).catch(err => {
      })
    },
    onReasonChange(value) {
      if(value=='0')  this.selcity = this.secondreson[0];
      if(value=='1')  this.selcity = this.secondreson[1];
      if(value=='2')  this.selcity = this.secondreson[2];
      if(value=='3')  this.selcity = this.secondreson[3];
      if(value=='4')  this.selcity = this.secondreson[4];
      if(value=='5')  this.selcity = this.secondreson[5];
      if(value=='6')  this.selcity = this.secondreson[6];
      if(value=='7')  this.selcity = this.secondreson[7];
      if(value=='8')  this.selcity = this.secondreson[8];
      this.seconds=this.selcity[0];
    },
    //切换显示平台还是APP图片
    appOrPlat(index){
      this.pc=index
      if(index==true){
        this.buttonIndex=1
      }else{
        this.buttonIndex=2
        this.getAPPImg()
      }
    },
    //困人图片
    getPic() {
      var that = this;
      for (let i = 1; i <= 3; i++) {
        (function (i) {
          setTimeout(function () {
            let params = {
              devicenum: that.alarmfiles.devicenum,
              eventtype: '2',
              eventcode: that.alarmfiles.eventcode,
              eventstarttime:  that.alarmfiles.calledtime,
              picNum: i
            }
            //请求图片接口并且对文件流进行处理
            viewUserImg(params).then((res) => {
              // var data = res.data;
              if(res){
                this.fileField =
                    "data:image/jpg;base64," +
                    btoa(
                        new Uint8Array(res).reduce(
                            (res, byte) => res + String.fromCharCode(byte),
                            ""
                        )
                    );
                that.urlList.push(this.fileField)
                if(res.byteLength>1000){
                  that.picVisible=true
                }
                if(that.urlList.length>0) {
                  if (that.urlList.length == 1) {
                    that.picUrl1 = that.urlList[0]
                  } else if (that.urlList.length ==2) {
                    that.picUrl2 = that.urlList[1]
                  } else if (that.urlList.length ==3) {
                    that.picUrl3 = that.urlList[2]
                  } else {
                    that.urlList = []
                    that.modalVisible = false
                  }
                }
              }else{
                console.log("未查找到相应图片")
              }
            })
          }, 300 * i);
        })(i);
      }
    },
    //获取APP上传图片
    getAPPImg(){
      this.locaImgLists=[]
      this.imgLists=[]
      let params = {
        alarmid:this.alarmfiles.alarmid
      }
      findRescuepicByAlarmId(params).then(res=>{
        if(res&&res.returncode=='0'&&res.item.length>0){
          this.imgVisible=true
          for(var i=0;i<res.item.length;i++){
            // if(res.item[i].pictype=='21'&&res.item[i].picurl!=''){
            //   this.locaImgLists.push(this.baseUrl+res.item[i].picurl)
            // }else if(res.item[i].picurl!=''){
            //   this.imgLists.push(this.baseUrl+res.item[i].picurl)
            // }
            if(res.item[i].pictype=='11'){
              this.img1=this.baseUrl+res.item[i].picurl
            }else if(res.item[i].pictype=='21'){
              this.img2=this.baseUrl+res.item[i].picurl
            }else if(res.item[i].pictype=='22'){
              this.video=this.baseUrl+res.item[i].picurl
            }else if(res.item[i].pictype=='23'){
              this.img3=this.baseUrl+res.item[i].picurl
            }
          }
        }else{
          this.imgVisible=false
          this.$message.error("未找到相应图片")
        }
      })
    },
    getRecords() {
      let params = {
        caller: this.callers,
        begintime: moment((moment(this.alarmfiles.calledtime,"YYYY-MM-DD HH:mm:ss").unix()-90)*1000).format("YYYYMMDDHHmmss"),
        endtime: this.alarmfiles.savetime,
      }
      this.detaildata = params
      this.checkTraceVisible = true
    },
    //保存
    save(){
      let params = {
        ...this.alarmfiles
        }
      modifyAlarmFiles(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("保存成功")
          this.close()
        }else{
          this.$message.error("保存失败")
        }
      })
    },
    //关闭
    close(){
      this.modalVisible = false
    },
  }
}
</script>
<style lang="scss" scoped>
.monitor{
  margin-left: 930px;
  margin-bottom: 2px;
}
.message{
  margin-left: 930px;
  margin-bottom: 2px;
}
.form-in-fourcolumns {
  &.ant-form .ant-form-item {
    width: calc(25% - 16px);

    &.all-line {
      width: auto;
      min-width: calc(25% - 16px);
    }

    &.showellipse {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.save{
  margin-left: 400px;
}
</style>